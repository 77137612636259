const appConfig = window['inject']?.['CONFIG'] ?? {};

const monieCRMUrl = appConfig.MONIECRM_URL ?? 'https://cdn-staging.moniepoint.com/moniecrm';
const addressVerificationUrl =
    appConfig.ADDRESS_VERIFICATION_MFE_URL ?? 'https://cdn-staging.moniepoint.com/address-verification-web/address-verification';
const ticketManagerUrl = appConfig.TICKET_MANAGER_URL ?? 'https://cdn-staging.moniepoint.com/moniedesk/ticket-manager-ui-dev/ticket-manager';
const growthURL = appConfig.GROWTH_URL ?? 'https://cdn-staging.moniepoint.com/growth-dev/growth';
const growthV2URL = appConfig.GROWTH_V2_URL ?? 'https://cdn-staging.moniepoint.com/growth-dev/growth-v2';
const paymentUrl = appConfig.MONIEPOINT_PAYMENT_URL ?? 'https://cdn-staging.moniepoint.com/payments';
const settingsUrl = appConfig.SETTINGS_MFE_URL ?? 'https://cdn-staging.moniepoint.com/settings/settings';
const loansDashboardWidgetUrl = appConfig.LOANS_DASHBOARD_WIDGET_URL ?? 'https://cdn-staging.moniepoint.com/loans/dev/dashboard-widget';
const loansAppUrl = appConfig.LOANS_HOST_URL ? appConfig.LOANS_HOST_URL : 'https://cdn-staging.moniepoint.com/loans/dev/host';
const profileSetupUrl = appConfig.PROFILE_SETUP_MFE_URL ?? 'https://cdn-staging.moniepoint.com/onboarding/bo-profile-setup';
const moniebookUrl = appConfig.MONIEBOOK_DISTRIBUTION_REMOTE_URL ?? 'https://cdn-staging.moniepoint.com/moniebook-dev/distribution-ui';
const accountUpgradeURL = appConfig.ACCOUNT_UPGRADE_MFE_URL ?? 'https://cdn-staging.moniepoint.com/account-upgrade-dev';
const identityVerificationUrl = appConfig.MFE_CDN_IDENTITY_VERIFICATION_URL ?? 'https://cdn-staging.moniepoint.com/onboarding/identity-verification';
const posUrl = appConfig.POS_MFE_URL ?? 'https://cdn-staging.moniepoint.com/pos-dev';
const marketerOnboardingUrl = appConfig.MARKETER_ONBOARDING_MFE_URL ?? 'https://cdn-staging.moniepoint.com/onboarding/marketer-onboarding';

export default {
    moniecrm: `${monieCRMUrl}/assets/remoteEntry.js`,
    addressVerification: `${addressVerificationUrl}/assets/remoteEntry.js`,
    payment: `${paymentUrl}/assets/remoteEntry.js`,
    growth: `${growthURL}/assets/remoteEntry.js`,
    growthV2: `${growthV2URL}/assets/remoteEntry.js`,
    settings: `${settingsUrl}/assets/remoteEntry.js`,
    loansDashboardWidget: `${loansDashboardWidgetUrl}/assets/remoteEntry.js`,
    profileSetup: `${profileSetupUrl}/assets/remoteEntry.js`,
    loans: `${loansAppUrl}/assets/remoteEntry.js`,
    moniebookDistribution: `${moniebookUrl}/assets/remoteEntry.js`,
    ticketManager: `${ticketManagerUrl}/assets/remoteEntry.js`,
    accountUpgrade: `${accountUpgradeURL}/host/assets/remoteEntry.js`,
    partnerOnboarding: `${accountUpgradeURL}/partner-onboarding/assets/remoteEntry.js`,
    identityVerification: `${identityVerificationUrl}/assets/remoteEntry.js`,
    pos: `${posUrl}/assets/remoteEntry.js`,
    marketerOnboarding: `${marketerOnboardingUrl}/assets/remoteEntry.js`,
};
